
(function () {
    "use strict";

    $(document).ready(function () {

        //Cambio colore header scroll

        const isScrolling = () => {
            const headerEl = document.querySelector('.site-header')
            let windowPosition = window.scrollY > 80
            headerEl.classList.toggle('bg-primary', windowPosition)
        }
        window.addEventListener('scroll', isScrolling)


        //Animation burger
        $('.burger_menu').click(function () {
            if (!$('.burger_menu').hasClass('cross'))
                $('.burger_menu').addClass('cross');

            else
                $('.burger_menu').removeClass('cross');
        });

        $('.burger_menu').click(function () {
            $('.menu-mobile').toggleClass('menu_mobile-open');
        })

        //Accordion
        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("accordion-active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        }

           //Hero Slider
        $('.hero-container-slider').slick({
            arrows: true,
            dots: true,
            autoplay: true,
            fade:true,
            dotsClass: 'slick-dots',
            slidesToShow: 1,
            slidesToScroll: 1,
        });


        //Slider reviews
        $('.slider-reviews').slick({
            arrows: false,
            dots: true,
            autoplay: true,
            dotsClass: 'slick-dots',
            slidesToShow: 1,
            slidesToScroll: 1,
        });

        // Slider project

        $('.container-slider').slick({
            centerMode: true,
            arrows:false,
            centerPadding: '60px',
            slidesToShow: 3,
            responsive: [
                {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 2
                }
                },
                {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
                }
            ]
        });

        //Slider gallery project
        $('#gallery-project').slick({
            arrows: true,
            dots: true,
            autoplay: true,
            dotsClass: 'slick-dots',
            slidesToShow: 1,
            slidesToScroll: 1,
        });
	



    }); // close documet get ready

})(jQuery);

